import { ProductIdentifier } from "./types"
import { NostojsCallback } from "@nosto/client-lib"
import { resolveSlotId, SlotReference } from "@/utils/resolveSlotId"

export const nostojs = (cb: NostojsCallback) => window.nostojs(cb)

/**
 * Helper function to traverse the DOM tree, find the identifier of the slot that
 * was clicked and report a click event to Nosto.
 *
 * @param product - the identifier of the product
 * @param element - the element that was clicked.
 */
export function reportAddToCart(product: ProductIdentifier, slotIdOrElement: SlotReference) {
  const slotId = resolveSlotId(slotIdOrElement)
  if (slotId) {
    nostojs(api => api.reportAddToCart(product, slotId))
  } else {
    return Promise.reject(new Error("Unable to resolve the recommendation slot identifier"))
  }
}
