export type SlotReference = string | HTMLElement

/**
 * Resolves the nosto reference of a slotId or element.
 *
 * @param slotIdOrElement - the slotId or button whose slot identifier is to be resolved
 * @returns the identifier of the element or false, if none was found
 */
export function resolveSlotId(slotIdOrElement: SlotReference) {
  if (typeof slotIdOrElement === "string") {
    return slotIdOrElement
  }
  const refWrapper = slotIdOrElement.closest("[data-nosto-ref]")
  if (refWrapper) {
    return refWrapper.getAttribute("data-nosto-ref") ?? undefined
  }
  const element = slotIdOrElement.closest(".nosto_element")
  if (element?.hasAttribute("id")) {
    return element.getAttribute("id") ?? undefined
  }
}
