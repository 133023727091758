import { LineItem, Product } from "./types"

async function postAddToCart(cartId: string, cartItems = {}) {
  let input = "/api/storefront/cart"
  if (cartId !== null) {
    input = `/api/storefront/carts/${cartId}/items`
  }
  const response = await fetch(input, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(cartItems)
  })
  return await response.json()
}

async function getCartId() {
  const response = await fetch("/api/storefront/cart", {
    credentials: "same-origin"
  })
  const cartJson = await response.json<{ id: string }[]>()
  if (cartJson[0] && cartJson[0].id) {
    return cartJson[0].id
  }
  return null
}

async function sendItems(items: LineItem[] = []) {
  const lineItems = { lineItems: items }
  const cartId = await getCartId()
  return await postAddToCart(cartId!, lineItems)
}

/**
 * Wrapper functions for Bigcommerce's cart API to add a one or more items to the shopping cart.
 * The identifier specified is the identifier of the actual variant and not of the
 * parent product. This allows SKUs in Nosto recommendations to be added to the
 * cart directly.
 *
 * @see {@link https://developer.bigcommerce.com/api-docs/cart-and-checkout/working-sf-apis#working-sf-apis_storefront-cart}
 */

/**
 * @param products Products array with the identifier of the product and variant to add to the cart
 */
export function addMultipleProductsToCart(products: Product[] = []) {
  const lineItems: LineItem[] = []
  products.forEach(product => {
    const lineItem: LineItem = {
      quantity: 1,
      productId: product.productId!
    }
    if (product.variantId) {
      lineItem.variantId = product.variantId
    }
    lineItems.push(lineItem)
  })
  return sendItems(lineItems)
}

/**
 * @param product Products object with the identifier of the product and variant to add to the cart
 * @param quantity - the quantity to be added (or 1)
 */
export function addProductToCart(product: Product, quantity: number) {
  const lineItem: LineItem = {
    quantity: quantity || 1,
    productId: product.productId
  }
  if (product.variantId) {
    lineItem.variantId = product.variantId
  }
  return sendItems([lineItem])
}
